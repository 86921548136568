// @Firebase
import firebase from "gatsby-plugin-firebase"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user"))
    : {}

export const getUserExtra = () =>
  isBrowser() && window.localStorage.getItem("userExtra")
    ? JSON.parse(window.localStorage.getItem("userExtra"))
    : {}

export const setUser = async user => {
  // Extra
  if (isBrowser() && user && user.email) {
    await firebase
      .firestore()
      .collection("DTVecolDirectory")
      .doc(user.email)
      .get()
      .then(snapshot => {
        if (snapshot && snapshot.data() && snapshot.data().email) {
          window.localStorage.setItem("user", JSON.stringify(user))
          window.localStorage.setItem(
            "userExtra",
            JSON.stringify(snapshot.data())
          )
        }
      })
  } else {
    window.localStorage.setItem("user", JSON.stringify(user))
    window.localStorage.setItem("userExtra", JSON.stringify(user))
  }
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.email
}

export const logout = firebase => {
  return new Promise(resolve => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        setUser({})
        resolve()
      })
  })
}
